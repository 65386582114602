import React, { ChangeEvent, MouseEvent, useEffect, useState } from "react";
import { Button, MenuItem, TextField } from "@material-ui/core";
import { DataGrid, GridColDef, GridCellParams, GridToolbarContainer, GridToolbarExport, GridRowId } from "@material-ui/data-grid";
import clsx from 'clsx';
import { createStyles, makeStyles } from '@material-ui/styles';
import { createTheme, Theme } from '@material-ui/core/styles';

import "../styles/pages/statuspedidos.css";
import api from "../services/api";

import { pedidoDetProps, logEntregaProps } from "../interfaces";
import { isNumber } from "util";
import { error } from "console";

import { FormControlLabel, IconButton } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import { green } from '@material-ui/core/colors';
import { yellow } from '@material-ui/core/colors';
import { red } from '@material-ui/core/colors';

function customCheckbox(theme: Theme) {
    return {
        '& .MuiCheckbox-root svg': {
            width: 16,
            height: 16,
            backgroundColor: 'transparent',
            border: `1px solid ${theme.palette.type === 'light' ? '#d9d9d9' : 'rgb(67, 67, 67)'
                }`,
            borderRadius: 2,
        },
        '& .MuiCheckbox-root svg path': {
            display: 'none',
        },
        '& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg': {
            backgroundColor: '#1890ff',
            borderColor: '#1890ff',
        },
        '& .MuiCheckbox-root.Mui-checked .MuiIconButton-label:after': {
            position: 'absolute',
            display: 'table',
            border: '2px solid #fff',
            borderTop: 0,
            borderLeft: 0,
            transform: 'rotate(45deg) translate(-50%,-50%)',
            opacity: 1,
            transition: 'all .2s cubic-bezier(.12,.4,.29,1.46) .1s',
            content: '""',
            top: '50%',
            left: '39%',
            width: 5.71428571,
            height: 9.14285714,
        },
        '& .MuiCheckbox-root.MuiCheckbox-indeterminate .MuiIconButton-label:after': {
            width: 8,
            height: 8,
            backgroundColor: '#1890ff',
            transform: 'none',
            top: '39%',
            border: 0,
        },
    };
}

const defaultTheme = createTheme();
const useStyles = makeStyles(
    (theme: Theme) =>
        createStyles({
            root: {
                border: 0,
                color:
                    theme.palette.type === 'light'
                        ? 'rgba(0,0,0,.85)'
                        : 'rgba(255,255,255,0.85)',
                fontFamily: [
                    '-apple-system',
                    'BlinkMacSystemFont',
                    '"Segoe UI"',
                    'Roboto',
                    '"Helvetica Neue"',
                    'Arial',
                    'sans-serif',
                    '"Apple Color Emoji"',
                    '"Segoe UI Emoji"',
                    '"Segoe UI Symbol"',
                ].join(','),
                WebkitFontSmoothing: 'auto',
                letterSpacing: 'normal',
                '& .MuiDataGrid-columnsContainer': {
                    backgroundColor: theme.palette.type === 'light' ? '#fafafa' : '#1d1d1d',
                },
                '& .MuiDataGrid-iconSeparator': {
                    display: 'none',
                },
                '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
                    borderRight: `1px solid ${theme.palette.type === 'light' ? '#f0f0f0' : '#303030'
                        }`,
                },
                '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
                    borderBottom: `1px solid ${theme.palette.type === 'light' ? '#f0f0f0' : '#303030'
                        }`,
                },
                '& .MuiDataGrid-cell': {
                    color:
                        theme.palette.type === 'light'
                            ? 'rgba(0,0,0,.85)'
                            : 'rgba(255,255,255,0.65)',
                },
                '& .MuiPaginationItem-root': {
                    borderRadius: 0,
                },
                ...customCheckbox(theme),
            },
        }),
    { defaultTheme },
);

let users = getUsers()

let usersArray: Array<any> = []
let columns: GridColDef[] = []

async function saveChanges(id: GridRowId, field: String, value: string, orderInfo: Array<any>) {
    let response = null
    let chave = orderInfo[id as any].chaveLog
    let numeroNFE = orderInfo[id as any].numeroNFE
    if (field == 'dataEntregaNew') {
        let date = new Date(value)
        date.setDate(date.getDate() + 1)
        date.setHours(0, 0, 0, 0);
        response = await api.post("/changesRelacionamento", { id, field, date, chave, numeroNFE });
        if (response.status === 200) {
            alert('Alterado com sucesso.');
        } else {
            alert('Ocorreu um erro.');
        }
    } else {
        response = await api.post("/changesRelacionamento", { id, field, value, chave, numeroNFE });
        if (response.status === 200) {
            alert('Alterado com sucesso.');
        } else {
            alert('Ocorreu um erro.');
        }
    }
}

async function getUsers() {
    const users = await api.post("/statuspedidos/users");
    usersArray = []
    usersArray?.push('Nao Tratado');
    users.data.forEach((user: any) => {
        usersArray?.push(user.userName)
    })

    columns = [
        {
            field: "status", headerName: "Status", width: 120, headerAlign: 'center', align: 'center', renderCell: (params) => {
                let icon = params.row.status
                if (icon) {
                    switch (icon) {
                        case 'ok':
                            return (
                                <div className="d-flex justify-content-between align-items-center">
                                    <CheckIcon style={{ color: green[500] }} />
                                </div>
                            );
                            break;

                        case 'atencao':
                            return (
                                <div className="d-flex justify-content-between align-items-center">
                                    <WarningIcon style={{ color: yellow[500] }} />
                                </div>
                            );
                            break;

                        case 'atraso':
                            return (
                                <div className="d-flex justify-content-between align-items-center">
                                    <ErrorIcon style={{ color: red[500] }} />
                                </div>
                            );
                            break;

                        default:
                            return
                            break;
                    }
                } else {
                    return ''
                }
            }
        },
        { field: "transportadoraDesc", headerName: "Transportadora", headerAlign: 'center', align: 'center', width: 170 },
        { field: "docCliente", headerName: "Doc Cliente", headerAlign: 'center', align: 'center', width: 150 },
        { field: "numeroNFE", headerName: "Numero NF", headerAlign: 'center', align: 'center', width: 150 },
        { field: "dataEmissao", headerName: "Emissao", headerAlign: 'center', align: 'center', width: 130 },
        { field: "statusOrderDesc", headerName: "Status Pedido", headerAlign: 'center', align: 'center', width: 270 },
        { field: "previsaoEntrega", headerName: "Previsao Entrega", headerAlign: 'center', align: 'center', width: 130 },
        { field: "criadoEm", headerName: "Criado Em", headerAlign: 'center', align: 'center', width: 150 },
        { field: "dtEntregaAg", headerName: "Data de Programação", headerAlign: 'center', align: 'center', width: 150 },
        { field: "dtMontagem", headerName: "Data de Montagem", headerAlign: 'center', align: 'center', width: 150 },
        { field: "codigo", headerName: "Codigo Recurso", headerAlign: 'center', align: 'center', width: 130 },
        { field: "descricao", headerName: "Nome Recurso", headerAlign: 'center', align: 'center', width: 350 },
        { field: "chCriacao", headerName: "Chave Criacao", headerAlign: 'center', align: 'center', width: 130 },
        { field: "orderId", headerName: "OrderId Vtex", headerAlign: 'center', align: 'center', width: 160 },
        { field: "sequence", headerName: "Sequence Vtex", headerAlign: 'center', align: 'center', width: 130 },
        { field: "loja", headerName: "Loja", headerAlign: 'center', align: 'center', width: 130 },
        { field: "dataEntrega", headerName: "Entrega", headerAlign: 'center', align: 'center', width: 130 },
        { field: "statusLogDesc", headerName: "Status Item Pedido", headerAlign: 'center', align: 'center', width: 270 },
        { field: "tratadoPor", headerName: "Tratado Por", width: 250, headerAlign: 'center', align: 'center', type: 'singleSelect', valueOptions: usersArray, editable: true },
        {
            field: "clienteNotificado", headerName: "Cliente Notificado", width: 150, headerAlign: 'center', align: 'center', type: 'singleSelect', valueOptions: [
                'SIM', 'NAO'
            ], editable: true
        },
        {
            field: "tratativa", headerName: "Atualização", width: 350, headerAlign: 'center', align: 'center', type: 'singleSelect', valueOptions: [
                'Entrega realizada', 'Processo de devolução', 'Solicitado prioridade',
                'Em rota pendente de finalização', 'Em processo dentro do prazo',
                'Possível extravio', 'Chamado aberto transportadora', 'Aguardando tratativa'
            ], editable: true
        },
        { field: "observacao", headerName: "Observação", headerAlign: 'center', align: 'center', width: 350, editable: true },
        { field: "dataEntregaNew", headerName: "Data de Entrega", type: 'date', headerAlign: 'center', align: 'center', width: 180, editable: true },

    ];
}

function StatusPedidos() {
    const [pedido, setPedido] = useState("");
    const [statusPedido, setStatusPedido] = useState("");
    const [statusItem, setStatusItem] = useState("");
    const [transportadora, setTransportadora] = useState("");
    const [dataInicio, setDataInicio] = useState("");
    const [dataFim, setDataFim] = useState("");

    const [orderInfo, setOrderInfo] = useState([]);
    const [searching, setSearching] = useState(false);

    if (usersArray.length == 0) getUsers()

    async function fetchData(e: MouseEvent) {
        try {
            e.preventDefault();
            if (!statusPedido || !dataInicio || !dataFim) return;

            const now = new Date(dataInicio);
            const past = new Date(dataFim);
            const diff = Math.abs(now.getTime() - past.getTime()); // Subtrai uma data pela outra
            const days = Math.ceil(diff / (1000 * 60 * 60 * 24));

            if (dataFim < dataInicio) {
                alert("Data Inicio não pode ser maior que Data Fim!");
                return;
            }

            if (days > 60) {
                alert("Diferença entre Datas limite é de 60 dias!");
                return;
            }

            setSearching(true);
            const response = await api.post("/statuspedidos", { pedido: pedido, dataInicio: dataInicio, dataFim: dataFim, statusPedido: statusPedido, statusItem: statusItem, transportadora: transportadora });
            setSearching(false);

            if (!response.data) setOrderInfo([]);

            let pedidoItens = Array<pedidoDetProps>()
            Object.keys(response.data.order).forEach(async e => {
                if (!isNaN(Number(e))) {
                    let cabecalho = {
                        chave: response.data.order[e].chave,
                        orderId: response.data.order[e].orderId,
                        sequence: response.data.order[e].sequence,
                        chCriacao: response.data.order[e].chCriacao,
                        loja: response.data.order[e].loja,
                        docCliente: response.data.order[e].docCliente,
                        statusOrderDesc: convertendoString(response.data.order.orderStatus[e].descricao),
                        previsaoEntrega: response.data.order[e].previsaoEntrega ? new Date(response.data.order[e].previsaoEntrega).toLocaleDateString(
                            "pt-BR"
                        ) : "",
                        dataEmissao: response.data.order[e].emissao ? new Date(response.data.order[e].emissao).toLocaleDateString(
                            "pt-BR"
                        ) : "",
                    };
                    let color = ''

                    if (statusPedido == '1') {
                        if (cabecalho.dataEmissao) {
                            const diffInMs = new Date(response.data.order[e].emissao).valueOf() - new Date().valueOf()
                            let diffInDays = Math.round(diffInMs / (1000 * 60 * 60 * 24))
                            if (Math.sign(diffInDays) == -1) {
                                if (diffInDays <= -2) {
                                    color = 'atraso'
                                }
                            }
                        }

                    } else if (statusPedido == '2') {
                        let prev = cabecalho.previsaoEntrega

                        if (prev && prev != '') {
                            const diffInMs = new Date(response.data.order[e].previsaoEntrega).valueOf() - new Date().valueOf()
                            let diffInDays = Math.round(diffInMs / (1000 * 60 * 60 * 24))
                            if (Math.sign(diffInDays) == 1) {
                                if (diffInDays >= 3) {
                                    color = 'ok'
                                } else {
                                    color = 'atencao'
                                }
                            } else {
                                color = 'atraso'
                            }
                        }
                    }

                    let itens = response.data.order[e].pedidoDet.map(
                        (itensResponse: pedidoDetProps) => {
                            return {
                                ...cabecalho,
                                chaveItem: itensResponse.chave,
                                codigo: itensResponse.codigo,
                                descricao: itensResponse.descricao,
                                quantidade: itensResponse.quantidade,
                                valorUnitario: itensResponse.valorUnitario,
                                status: color
                            };
                        }
                    );
                    pedidoItens.push(itens)
                }
            })

            pedidoItens = pedidoItens.reduce((acumulador, e: pedidoDetProps) => {
                return acumulador.concat(e)
            }, Array<pedidoDetProps>())
            const logs = response.data.logsEntrega.map(
                (logsResponse: logEntregaProps, index: Number) => {
                    let itemFiltered = pedidoItens.filter(
                        (item: any) => item.chaveItem === logsResponse.chItemPed
                    );

                    return {
                        id: index,
                        ...itemFiltered[0],
                        chaveLog: logsResponse.chave,
                        statusLogDesc: logsResponse.descricao ? convertendoString(logsResponse.descricao) : "",
                        transportadoraDesc: logsResponse.codigo,
                        numeroNFE: logsResponse.numeroDS ? logsResponse.numeroDS : logsResponse.numeroNFE,
                        criadoEm: new Date(logsResponse.created_at).toLocaleDateString(
                            "pt-BR"
                        ),
                        dtEntregaAg: logsResponse.dtProgramacaoErp ?
                            new Date(logsResponse.dtProgramacaoErp).toLocaleDateString("pt-BR") :
                            logsResponse.dtEntregaAg ? new Date(logsResponse.dtEntregaAg).toLocaleDateString("pt-BR") : ""
                        ,
                        dtMontagem: logsResponse.dtMontagem ? new Date(logsResponse.dtMontagem).toLocaleDateString(
                            "pt-BR"
                        ) : "",
                        dataEntrega: logsResponse.dataEntrega ? new Date(logsResponse.dataEntrega).toLocaleDateString(
                            "pt-BR"
                        ) : "",
                        tratadoPor: logsResponse.tratadoPor ? logsResponse.tratadoPor : "Nao Tratado",
                        clienteNotificado: logsResponse.clienteNotificado ? logsResponse.clienteNotificado : "NAO",
                        tratativa: logsResponse.tratativa ? logsResponse.tratativa : "Aguardando tratativa",
                        observacao: logsResponse.observacao,
                        dataEntregaNew: logsResponse.zDataAgendada ? new Date(logsResponse.zDataAgendada).toLocaleDateString(
                            "pt-BR"
                        ) : logsResponse.dataEntregaNew ? new Date(logsResponse.dataEntregaNew).toLocaleDateString(
                            "pt-BR"
                        ) : null,
                    };
                }
            );
            setOrderInfo(logs);
        } catch (error) {
            console.log(error);
            setSearching(false);
        }
    }

    const classes = useStyles();

    return (
        <div className="page-statuspedidos">
            <h4>CONSULTA STATUS DE PEDIDOS</h4>
            <div className="search-content" id="search-content">
                <TextField
                    id="search-pedido"
                    label="Pedido"
                    variant="outlined"
                    size="small"
                    value={pedido}
                    onChange={(e) => setPedido(e.target.value)}
                />&nbsp;&nbsp;&nbsp;

                <TextField id="search-dataInicio"
                    type="date"
                    variant="outlined"
                    label="Data Inicio"
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    required={true}
                    value={dataInicio}
                    onChange={(e) => setDataInicio(e.target.value)}
                />&nbsp;&nbsp;&nbsp;

                <TextField id="search-dataFim"
                    type="date"
                    variant="outlined"
                    label="Data Fim"
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    required={true}
                    value={dataFim}
                    onChange={(e) => setDataFim(e.target.value)}
                />&nbsp;&nbsp;&nbsp;

                <TextField id="search-statusPedido"
                    select
                    required={true}
                    variant="outlined"
                    label="Status Pedido"
                    size="small"
                    value={statusPedido}
                    onChange={(e) => setStatusPedido(e.target.value)}
                >
                    <MenuItem value="1">Prazo de Expedição</MenuItem>
                    <MenuItem value="2">Expedido</MenuItem>
                    <MenuItem value="3">Entrega Realizada</MenuItem>
                    <MenuItem value="4">Cancelado</MenuItem>
                    <MenuItem value=""></MenuItem>
                </TextField>&nbsp;&nbsp;&nbsp;

                <TextField id="search-statusItem"
                    select
                    variant="outlined"
                    label="Status Item"
                    size="small"
                    value={statusItem}
                    onChange={(e) => setStatusItem(e.target.value)}
                >
                    <MenuItem value="1">Ocorrência</MenuItem>
                    <MenuItem value="2">Extravio</MenuItem>
                    <MenuItem value="3">Sinistro Liquidado</MenuItem>
                    <MenuItem value="4">Coletado no Cliente</MenuItem>
                    <MenuItem value="5">Entrega Realizada</MenuItem>
                    <MenuItem value="6">Devolvido ao CD</MenuItem>
                    <MenuItem value=""></MenuItem>
                </TextField>&nbsp;&nbsp;&nbsp;

                <TextField id="search-transportadora"
                    select
                    variant="outlined"
                    label="Transportadora"
                    size="small"
                    value={transportadora}
                    onChange={(e) => setTransportadora(e.target.value)}
                >
                    <MenuItem value="1">Total</MenuItem>
                    <MenuItem value="2">Fedex</MenuItem>
                    {/* <MenuItem value="3">Correios</MenuItem> */}
                    <MenuItem value="4">Entrega Abra</MenuItem>
                    <MenuItem value="5">Braspress</MenuItem>
                    {/* <MenuItem value="6">AndLog</MenuItem>*/}
                    <MenuItem value="7">I9</MenuItem>
                    <MenuItem value="8">Sequoia</MenuItem>
                    <MenuItem value="8">Dropshipping</MenuItem>
                    <MenuItem value="10">Retirada</MenuItem>
                    <MenuItem value="11">Moovelog</MenuItem>
                    <MenuItem value=""></MenuItem>
                </TextField>
                <Button variant="contained" onClick={(e) => { fetchData(e) }}>
                    {searching ? "Buscando" : "Buscar"}
                </Button>
            </div>

            <div className="data-content">
                <DataGrid
                    className={classes.root}
                    rows={orderInfo}
                    getRowClassName={(params) =>
                        `super-app-theme--${params.getValue(params.id, 'status')}`
                    }
                    columns={columns}
                    pageSize={20}
                    checkboxSelection
                    onCellEditCommit={(params, event) => {
                        let tgt = event.target as HTMLInputElement
                        saveChanges(params.id, params.field, tgt.value!.toString(), orderInfo)
                    }}
                    components={{
                        Toolbar: CustomToolbar,
                    }}
                />
            </div>
        </div>
    );
}

function convertendoString(texto: String) {
    var loweredText = texto.toLowerCase();
    var words = loweredText.split(" ");
    for (var a = 0; a < words.length; a++) {
        var w = words[a];
        var firstLetter = w.charAt(0)
        w = firstLetter.toUpperCase() + w.slice(1);
        words[a] = w;
    }
    return words.join(" ");
}

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarExport />
        </GridToolbarContainer>
    );
}

export default StatusPedidos;
