import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import styles from './Home.module.css';
import { TextField } from "@material-ui/core";
import api from "../services/api";

import logoCasa from "../images/casa-logo.svg";
import { Toaster, toast } from 'sonner';

function Home() {
  const [documento, setDocumento] = useState('');
  const [captchaValido, setCaptchaValido] = useState(false);
  const [searching, setSearching] = useState(false);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      if (!documento || documento === "") return toast.warning("Obrigatório informar um documento.");
      if (documento.length < 11) return toast.warning("Documento inválido.");

      if (!captchaValido) return toast.warning("Por favor, complete o captcha.");

      setSearching(true);

      toast.info("Aguarde...");
      const response = await api.post("/pedidos/owner", { document: documento.replace(/\D/g, '') });
      if (response?.data?.success) {
        localStorage.setItem('orders', JSON.stringify(response.data));
        localStorage.setItem('loggedDateTime', new Date()?.toString());
        window.location.href = '/meus-pedidos';
      } else {
        toast.error("Ocorreu um erro ao buscar os pedidos. Por favor, tente novamente em breve.");
      }

    } catch (e) {
      console.log(e?.response);
      toast.error(e?.response?.data?.message || "Ocorreu um erro ao buscar seus pedidos. Por favor, tente novamente em breve.");
    } finally {
      setSearching(false);
    }
  };

  const formatarDocumento = (value) => {
    const digits = value.replace(/\D/g, '');

    if (digits.length <= 11) {
      return digits
        .replace(/^(\d{3})(\d)/, '$1.$2')
        .replace(/^(\d{3})\.(\d{3})(\d)/, '$1.$2.$3')
        .replace(/\.(\d{3})(\d{1,2})$/, '.$1-$2');
    } else {
      return digits
        .replace(/^(\d{2})(\d)/, '$1.$2')
        .replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
        .replace(/\.(\d{3})(\d)/, '.$1/$2')
        .replace(/\/(\d{4})(\d{1,2})$/, '/$1-$2');
    }
  };

  const handleDocumentoChange = (e) => {
    const formatted = formatarDocumento(e.target.value);
    setDocumento(formatted);
  };


  return (
    <div className={styles.page}>

      <main className={styles.container}>
        <div className={styles.containerChildren}>
          <div className={styles.informations}>
            <span className={styles.welcome}>
              👋 Olá! Bem-vindo ao nosso rastreio.
            </span>
            <span>
              📦 Informe seu documento para visualizar os pedidos.
            </span>
          </div>
          <div className={styles.logos}>
            <a
              href="https://www.abracasa.com.br/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                className={styles.logo}
                src={logoCasa}
                alt="Abra Casa"
              />
            </a>
            <div
              className={styles.firstStep}>
              Da
              <span className={`${styles.step} ${styles.ourStep}`}
              > nossa
              </span>
              <span> casa para a</span>
              <span className={`${styles.step} ${styles.yourStep}`}
              > sua
              </span>
              .
            </div>
          </div>
        </div>
        <div className={styles.pipe}></div>
        <form onSubmit={handleSubmit} className={styles.form}>
          <TextField
            id="document"
            label="CPF/CNPJ"
            variant="outlined"
            size="small"
            value={documento}
            onChange={handleDocumentoChange}
            inputProps={{
              inputMode: 'numeric',
              pattern: '[0-9]*',
              maxLength: 18,
            }}
          />

          <div className={styles.captchaContainer}>
            <ReCAPTCHA
              sitekey="6LedhJAqAAAAAKJVr3WL_yLTVY5G4cbzZ40KNoxH"
              onChange={() => setCaptchaValido(true)}
            />
          </div>

          <button
            variant="contained"
            className={styles.button}
            onClick={(e) => handleSubmit(e)}
            disabled={searching}
          >
            {searching ? "Buscando..." : "Buscar"}
          </button>
        </form>
        <div className={styles.logosMobile}>
          <a
            href="https://www.abracasa.com.br/"
            target="_blank"
            rel="noreferrer noopener"
          >
            <img
              className={styles.logo}
              src={logoCasa}
              alt="Abra Casa"
            />
          </a>
          <div
            className={styles.firstStep}>
            Da
            <span className={`${styles.step} ${styles.ourStep}`}
            > nossa
            </span>
            <span> casa para a</span>
            <span className={`${styles.step} ${styles.yourStep}`}
            > sua
            </span>
            .
          </div>
        </div>
      </main>
      <Toaster />
    </div>
  );
}

export default Home; 