import React, { useEffect, useState } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import "../styles/pages/cabgrid.css";
import iconEntrega from "../images/car-black-icon.svg";
import iconMontagem from "../images/tools-icon.svg";
import IconArrowDown from '../images/arrow-down.svg';
import api from "../services/api";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
  })
);
interface PropriedadesCabGrid {
  descricaoItem?: string;
  quantidadePreco?: string;
  dataEntrega?: string;
  dataMontagem?: string;
  dataEntregaAg?: string;
  dataRetirada?: string;
  isRetirada?: boolean;
  transportadora?: string;
  isRoteiro?: boolean;
  dtRotaEntrega?: string;
  chCriacao?: number;
  orderId?: string;
  status?: number;
  codigo?: string;
  zDataAgendada?: string;
}

export default function CabGrid(props: PropriedadesCabGrid) {
  const classes = useStyles();

  const [dataRota, setDataRota] = useState(null);
  const [horarioRota, setHorarioRota] = useState(null);
  const transportadorasRoteiro: Number[] = [8, 14, 15, 16, 17, 18] //roteiro
  const transportadorasAgendado: Number[] = [55, 66, 67, 68] //I9 agendada
  let isRoteiro = false
  let isAgendada = props.zDataAgendada ? true : false;

  if (!isRoteiro) {
    if (props) {
      if (props.isRoteiro) {
        isRoteiro = props.isRoteiro
      } else if (props.transportadora) {
        isRoteiro = transportadorasRoteiro.find((el) => el.toString() == props.transportadora) ? true : false
      }
    }
  }

  if (!isAgendada) {
    if (props) {
      if (props.transportadora) {
        isAgendada = transportadorasAgendado.find((el) => el.toString() == props.transportadora) ? true : false
      }
    }
  }

  async function getInfoRota(chCriacao: string, type: string, codigo: string) {
    const response = await api.get(`/pedidos/infoRota/${chCriacao}/${type}/${codigo}`);
    if (response.status == 200 && response.data) return response.data
  }

  useEffect(() => {
    const fetchData = async (dataAtual: string) => {
      try {
        if (props.chCriacao || props.orderId) {
          let result = null
          if (props.codigo) {
            if (props.chCriacao) {
              result = await getInfoRota(props.chCriacao.toString(), 'chCriacao', props.codigo)
            } else if (props.orderId) {
              result = await getInfoRota(props.orderId, 'orderId', props.codigo)
            }
          }

          if (result.sucesso && result.dataFormatada == dataAtual) {            
            setDataRota(result.dataFormatada);
            setHorarioRota(result.horario);
          }
        }
      } catch (error) {
        console.error('Erro:', error);
      }
    };

    console.log({isRoteiro, status: props.status, dataEntregaAg: props.dataEntregaAg, codigo: props.codigo})

    if (isRoteiro && props.status == 314 && props.dataEntregaAg && props.dataEntregaAg !== '') { //apenas roteiro, entregue a transportadora e com data de entrega
      let elementosData = props.dataEntregaAg.split("/");
      let dataRotaString = elementosData[1] + "/" + elementosData[0] + "/" + elementosData[2];
      let novaDataRota = new Date(dataRotaString);
      let hoje = new Date();

      let dataRota = null;
      let dataAtual = null;

      if (hoje.getHours() < 9) return

      hoje.setHours(0, 0, 0, 0)

      let dia = ('0' + novaDataRota.getDate()).slice(-2);
      let mes = ('0' + (novaDataRota.getMonth() + 1)).slice(-2);
      let ano = novaDataRota.getFullYear();

      dataRota = dia + '/' + mes + '/' + ano;

      let diaAtual = ('0' + hoje.getDate()).slice(-2);
      let mesAtual = ('0' + (hoje.getMonth() + 1)).slice(-2);
      let anoAtual = hoje.getFullYear();

      dataAtual = diaAtual + '/' + mesAtual + '/' + anoAtual;

      console.log('dataRota -> ' + dataRota)
      if (dataRota == dataAtual) fetchData(dataAtual); //apenas no dia da entrega
    }
  }, []); 

  return (
    <div className={classes.root}>
      <div className="cabgrid-content">
          <img className="cabgrid-arrow-down" src={IconArrowDown} alt="arrow-down"/>
        <div className="cabgrid-left-content">
          <span className="cabgrid-desc-item">{props.descricaoItem}</span>
          <span className="cabgrid-preco-item">{props.quantidadePreco}</span>
        </div>
        <div className="cabgrid-right-content">
          {!dataRota && !props.isRetirada && props.dataEntregaAg && isRoteiro && !isAgendada && (
            <div className="cabgrid-data-entrega-agendada">
              <img src={iconEntrega} alt="data_entrega_agendada" />
              <span>Entrega Agendada</span>
              <strong>{props.dataEntregaAg}</strong>
            </div>
          )}
          {!dataRota && !props.isRetirada && props.zDataAgendada && !isRoteiro && isAgendada && (
            <div className="cabgrid-data-entrega-agendada">
              <img src={iconEntrega} alt="data_entrega_agendada" />
              <span>Entrega Agendada</span>
              <strong>{props.zDataAgendada}</strong>
            </div>
          )}
          {/* { props.dataEntrega && (
            <div className="cabgrid-data-entrega">
              <img src={iconEntrega} alt="data_entrega" />
              <span>Data Limite de Entrega </span>
              <strong>{props.dataEntrega}</strong>
            </div>
          )} */}
          {dataRota && horarioRota && (
            <div className="cabgrid-data-entrega">
              <img src={iconEntrega} alt="data_entrega" />
              <span>Entrega Agendada</span>
              <strong>{dataRota}</strong>
              <strong>{horarioRota}</strong>
            </div>
          )}
          {props.isRetirada && props.dataRetirada && (
            <div className="cabgrid-retirada">
              <img src={iconEntrega} alt="data_retirada" />
              <span>Retirada</span>
              <strong>{props.dataRetirada}</strong>
            </div>
          )}
          {props.dataMontagem && (
            <div className="cabgrid-montagem">
              <img src={iconMontagem} alt="data_montagem" />
              <span>Montagem</span>
              <strong>{props.dataMontagem}</strong>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
