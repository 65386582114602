import React, { useEffect } from 'react';
import ListItem from '../components/ListItem';
import styles from './Lista.module.css';
import logoCasa from "../images/casa-logo.svg";

function Lista() {
  const storage = localStorage.getItem('orders');
  const parsedOrders = storage ? JSON.parse(storage) : null;

  const backToHome = () => {
    localStorage.clear();
    window.location.href = '/';
  };

  useEffect(() => {
    if (!parsedOrders) return backToHome();

    if (localStorage.getItem('loggedDateTime')) {
      const dateDifference = DateDifference(localStorage.getItem('loggedDateTime'));

      if (dateDifference && dateDifference >= 120) backToHome();
    }
  }, [parsedOrders]);

  const DateDifference = (dateInput) => {
    const inputDate = new Date(dateInput);
    const now = new Date();

    const diffInMs = now - inputDate;
    const diffInMinutes = Math.floor(diffInMs / 60000);
    return diffInMinutes;
  };

  return (
    <>
      <header className={styles.header}>
        <div className={styles.headerContent}>
          <a
            href="https://www.abracasa.com.br/"
            target="_blank"
            rel="noreferrer noopener"
          >
            <img className="header-logo" src={logoCasa} alt="Casa" />

          </a>
        </div>
        <div className={styles.backButton} onClick={() => { backToHome() }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-arrow-left-from-line"><path d="m9 6-6 6 6 6" /><path d="M3 12h14" /><path d="M21 19V5" /></svg>
        </div>
      </header>
      <div className={styles.pageContainer}>
        <h1>Lista de Pedidos</h1>
        <div className={styles.listContainer}>
          {parsedOrders?.data?.map((item) => (
            <ListItem
              key={item?.identifier}
              {...item}
            />
          ))}
        </div>
      </div>
    </>
  );
}

export default Lista; 