import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import AccordionItem from "../components/DetailedAccordion";

import "../styles/pages/principal.css";

import logoCasa from "../images/casa-logo.svg";
import buildingContent from '../images/building-content.png';
import api from "../services/api";

import { orderProps } from "../interfaces/index";
import PrincipalLoader from "../components/PrincipalLoader";
import { LinearProgress } from "@material-ui/core";

import CanceladoActive from '../images/cancel-icon.svg';

interface paramsPrincipal {
  orderId: string;
  chCriacao: string;
  arquiteto: string;
}

const inicialState = {
  order: {
    orderStatus: {
      chave: 0,
      codigo: "",
      descricao: "",
      created_at: "",
      updated_at: "",
    },
    chave: 0,
    orderId: "",
    sequence: "",
    emissao: "",
    hashURL: "",
    status: 0,
    created_at: "",
    updated_at: 0,
    loja: "",
    valor: 0,
    previsaoEntrega: "",
    isPromocao: false,
    chCriacao: 0,
    pedidoDet: [],
  },
  logsEntrega: [],
};

function Principal() {
  const { orderId } = useParams<paramsPrincipal>();
  const [loading, setLoading] = useState(false);
  const [noContent, setNoContent] = useState(false);
  const [cancelado, setCancelado] = useState(false);
  const [orderInfo, setOrderInfo] = useState<orderProps>(inicialState);
  const [isLoading, setIsLoading] = useState(true);
  const [isRoteiro, setIsRoteiro] = useState(false);

  async function fetchData() {
    try {
      setLoading(true);
      const response = await api.get(`/acompanhamento/${orderId}`);

      setIsRoteiro(
        response.data.logsEntrega[0] && response.data.logsEntrega[0].dtEntregaAg
          ? true
          : false
      );

      setTimeout(() => {
        setOrderInfo(response.data);
        if (response.data) {
          if (response.data.order.status === 7 && (!response.data.order.pedidoDet || response.data.order.pedidoDet.length == 0)) {
            setCancelado(true);
          } else {
            if (!response.data.order.pedidoDet || response.data.order.pedidoDet.length == 0) setNoContent(true);
          }
        }
        setLoading(false);
      }, 1000);

    } catch (error) {
      console.log(error);
      setLoading(false);
      setNoContent(true);
    }
  }

  useEffect(() => {
    fetchData();
    setIsLoading(false);
  }, []);

  function handleFormatData() {
    const opcoesData: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    };
    let dataFormat = new Date(orderInfo.order.updated_at);
    return dataFormat.toLocaleDateString("pt-br", opcoesData).split(" ");
  }

  function handleDescStatus(
    statusOrder: string,
    isRetirada: boolean | undefined
  ) {
    switch (statusOrder) {
      case 'Preparando Entrega':
        return "pedido recebido";
      case 'Faturado':
        return "Processo de separação";
      case 'Entregue a transportadora':
        return isRetirada ? "Consultar data da retirada" : "entregue a transportadora";
      case 'Cancelado':
        return "pedido cancelado";
      default:
        if (statusOrder.includes("ENTREGA REALIZADA") || statusOrder.includes("Entrega Realizada Normalmente") || statusOrder.includes("Entrega Fora da Data Programada") || statusOrder.includes("Entrega com Indenização Efetuada") || statusOrder.includes("Entregue")) {
          return "pedido entregue";
        } else {
          return "pedido recebido";
        }
    }
  }

  return (
    <div id="page-principal">
      <div className="content-wrapper">
        {isLoading ? (
          <PrincipalLoader className="content-loader" />
        ) : (
          <>
            <div className="header-content">
              <a
                href="https://www.abracasa.com.br/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <img
                  className="header-logo"
                  src={logoCasa}
                  alt="Casa" />
              </a>
              <div className="header-right-content">
                <div className="backHome" onClick={() => { window.location.href = "/meus-pedidos" }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-arrow-left-from-line"><path d="m9 6-6 6 6 6" /><path d="M3 12h14" /><path d="M21 19V5" /></svg>
                  <strong>Meus Pedidos</strong>
                </div>
                {isRoteiro && (
                  <span>
                    A data de entrega do seu pedido <br /> já foi agendada.
                  </span>
                )}
              </div>
            </div>

            <div className="infopedido">
              <div className="infopedido-left-content">
                <strong>
                  #
                  {orderInfo.order.orderId
                    ? orderInfo.order.orderId
                    : orderInfo.order.chCriacao}
                </strong>
                {orderInfo.order.arquiteto &&
                  <span className="infopedido-left-content-architect">Arquiteto: {orderInfo.order.arquiteto}</span>
                }
              </div>
              <div className="infopedido-sepator-content">
                <hr></hr>
              </div>
              <div className="infopedido-right-content">
                <span>
                  Última interação: {handleFormatData()[0]}{" "}
                  <strong>{handleFormatData()[1]}</strong>
                </span>
                <strong>
                  Status:{" "}
                  <span className="infopedido-rigth-content status-desc">
                    {handleDescStatus(
                      orderInfo.order.orderStatus.descricao,
                      orderInfo.logsEntrega[0]?.isRetirada
                    )}
                  </span>
                </strong>
              </div>
            </div>
            {loading &&
              <>
                <div style={{ marginTop: '5vh' }}>
                  <LinearProgress color="secondary" style={{ backgroundColor: '#FBB813' }} />
                </div>
              </>
            }
            <div className="item-pedido">
              {cancelado && !loading &&
                <div className="no-content-detailed">
                  <img
                    className="building-content-img"
                    src={CanceladoActive}
                    alt="building-content"
                    id="building-content"
                  />
                </div>
              }
              {noContent && !loading &&
                <div className="no-content-detailed">
                  <img
                    className="building-content-img"
                    src={buildingContent}
                    alt="building-content"
                    id="building-content"
                  />
                  <span className="no-content-detailed-text">Em breve os detalhes do seu pedido serão exibidos aqui!</span>
                </div>
              }
              {orderInfo.order.pedidoDet && orderInfo.order.pedidoDet.length > 0 && orderInfo.order.pedidoDet?.map((itemOrder, index) => (
                <AccordionItem
                  key={index}
                  previsaoEntrega={orderInfo.order.previsaoEntrega}
                  pedidoDet={itemOrder}
                  logsEntrega={orderInfo.logsEntrega.filter(
                    (log) => log.chItemPed === itemOrder.chave
                  )}
                  chCriacao={orderInfo.order.chCriacao}
                  orderId={orderInfo.order.orderId}
                />
              ))}
            </div>
            <div className="content-footer">
              <div className="content-contato">
                <p>
                  Qualquer dúvida, entre em contato pelo nosso WhatsApp (21) 97117-4398.
                </p>
                <br />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Principal;
